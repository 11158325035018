import { sampleLocationNames } from "./sampleNames";
import { random, selectRandom, selectUniqueRandom } from "./helpers";

const generateLoctions = (width, height, numberOfLocations) => {
  const locations = [];

  const center = {
    x: width / 2,
    y: height / 2,
  };
  const angle = (Math.PI * 2) / (numberOfLocations - 1);

  var name = selectRandom(sampleLocationNames.hive);
  locations.push({
    id: 0,
    name: name,
    point: center,
    type: "hive",
    connections: [],
    roadConnections: [],
  });

  for (var i = 1; i < numberOfLocations; i++) {
    const type = i % 2 === 0 ? "city" : "town";
    const name = selectUniqueRandom(
      sampleLocationNames[type],
      locations.map((l) => l.name)
    );
    const distance =
      i % 2 === 0
        ? random((center.y / 3) * 2, center.y - 25)
        : random(center.y / 3, (center.y / 3) * 2);
    const point = {
      x: center.x + distance * Math.cos(angle * i),
      y: center.y + distance * Math.sin(angle * i),
    };

    locations.push({
      id: i,
      name,
      point,
      type,
      connections: [],
      roadConnections: [],
    });
  }

  return locations.sort((a, b) => a.name.localeCompare(b.name));
};

export { generateLoctions };
