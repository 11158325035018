import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FacebookShareButton, FacebookIcon } from "react-share";
import PuffLoader from "react-spinners/PuffLoader";
import {
  faBars,
  faFloppyDisk,
  faLocationDot,
  faMap,
  faRoad,
  faRoute,
  faUsers,
  faImage,
} from "@fortawesome/free-solid-svg-icons";

import Locations from "./components/location/Locations";
import Map from "./components/map/Map";
import Players from "./components/players/Players";
import Roads from "./components/road/Roads";
import TradeRoutes from "./components/tradeRoutes/TradeRoutes";
import { generateLoctions } from "./classes/locationGenerator";
import { generateRoads } from "./classes/roadsGenerator";
import { generateTradeRoutes } from "./classes/tradeRouteGenerator";
import playerColors from "./classes/playerColors";

import "./App.scss";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(8);
  const [ownerId, setOwnerId] = useState(null);
  const [publicId, setPublicId] = useState(null);
  const [numberOfPlayers, setNumberOfPlayers] = useState(8);
  const [numberOfLocations, setNumberOfLocations] = useState(12);
  const [numberOfTradeRoutes, setNumberOfTradeRoutes] = useState(8);
  const [showMenu, setShowMenu] = useState(false);

  const [selectedTab, setSelectedTab] = useState("map");
  const [data, setData] = useState({
    locations: [],
    roads: [],
    tradeRoutes: [],
    players: [],
  });

  useEffect(() => {
    if (location.pathname === "/") {
      setIsAdmin(true);
      return;
    }
    const id = location.pathname.substring(1);

    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/Campaign/${id}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not get campaign at this time");
        }
        return response.json();
      })
      .then((data) => {
        const locations = data.locations;
        const roads = data.roads.map((r) => ({
          id: r.id,
          name: r.name,
          startPoint: r.startPoint,
          endPoint: r.endPoint,
          startLocation: r.startLocationId
            ? locations.find((l) => l.id === r.startLocationId)
            : null,
          endLocation: r.endLocationId
            ? locations.find((l) => l.id === r.endLocationId)
            : null,
          region: r.region,
          income: r.income,
          controllingPlayer: r.controllingPlayer,
        }));
        const tradeRoutes = data.tradeRoutes.map((t) => ({
          id: t.id,
          name: t.name,
          startLocation: locations.find((l) => l.id === t.startLocationId),
          endLocation: locations.find((l) => l.id === t.endLocationId),
          tradingBonus: t.tradingBonus,
          raidingBonus: t.raidingBonus,
          cost: t.cost,
        }));
        const players = data.players;

        setData({
          locations: locations,
          roads: roads,
          tradeRoutes: tradeRoutes,
          players: players,
        });
        setIsAdmin(data.ownerId === id);
        setPublicId(data.publicId);
        setOwnerId(data.ownerId);
        setLoading(false);
      })
      .catch((error) => {
        setApiError(error.message);
        setLoading(false);
      });
  }, [location.pathname]);

  const menuSelect = (item) => {
    setShowMenu(false);
    setSelectedTab(item);
  };

  const generate = () => {
    const width = 1080;
    const locations = generateLoctions(width, width, numberOfLocations);
    const players = Array.from({ length: numberOfPlayers }, (_, index) => ({
      id: index,
      playerName: `Player ${index + 1}`,
      gangName: "",
      faction: "Unknown",
      customFaction: "",
      alignment: "Trader",
      color: playerColors[index],
    }));
    const roads = generateRoads(locations, numberOfPlayers, players);
    const tradeRoutes = generateTradeRoutes(locations, numberOfTradeRoutes);

    setData({
      locations: locations,
      roads: roads,
      tradeRoutes: tradeRoutes,
      players: players,
    });
  };

  const saveMap = () => {
    setShowMenu(false);
    const saveData = JSON.stringify({
      locations: data.locations,
      roads: data.roads.map((r) => ({
        id: r.id,
        name: r.name,
        startPoint: r.startPoint,
        endPoint: r.endPoint,
        startLocationId: r.startLocation?.id ?? null,
        endLocationId: r.endLocation?.id ?? null,
        region: r.region,
        income: r.income,
        controllingPlayer: r.controllingPlayer,
      })),
      tradeRoutes: data.tradeRoutes.map((t) => ({
        id: t.id,
        name: t.name,
        startLocationId: t.startLocation.id,
        endLocationId: t.endLocation.id,
        tradingBonus: t.tradingBonus,
        raidingBonus: t.raidingBonus,
        cost: t.cost,
      })),
      players: data.players,
    });
    setLoading(true);
    if (ownerId) {
      fetch(`${process.env.REACT_APP_API_URL}/Campaign/${ownerId}`, {
        method: "Put",
        headers: { "Content-Type": "application/json" },
        body: saveData,
      })
        .then(() => {
          navigate(`/${ownerId}`);
          setLoading(false);
        })
        .catch((error) => {
          setApiError(error.message);
          setLoading(false);
        });
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/Campaign`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: saveData,
      })
        .then((response) => response.json())
        .then((data) => {
          navigate(`/${data.ownerId}`);
          setLoading(false);
        })
        .catch((error) => {
          setApiError(error.message);
          setLoading(false);
        });
    }
  };

  const saveImage = () => {
    const canvas = document.getElementById("map");
    const image = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);
    link.setAttribute("download", "map.png");
    link.setAttribute("href", image.replace("image/png", "image/octet-stream"));
    link.click();
  };

  const handleLocationSave = (location) => {
    const updatedLocations = data.locations.map((l) => {
      if (l === location) return location;
      return l;
    });
    setData({
      locations: updatedLocations.sort((a, b) => a.name.localeCompare(b.name)),
      roads: data.roads,
      tradeRoutes: data.tradeRoutes,
      players: data.players,
    });
  };

  const handleRoadSave = (road) => {
    const updatedRoads = data.roads.map((r) => {
      if (r === road) return road;
      return r;
    });
    setData({
      locations: data.locations,
      roads: updatedRoads.sort((a, b) => a.name.localeCompare(b.name)),
      tradeRoutes: data.tradeRoutes,
      players: data.players,
    });
  };

  const handleTradeRouteSave = (tradeRoute) => {
    const updatedTradeRoutes = data.tradeRoutes.map((t) => {
      if (t === tradeRoute) return tradeRoute;
      return t;
    });
    setData({
      locations: data.locations,
      roads: data.roads,
      tradeRoutes: updatedTradeRoutes.sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
      players: data.players,
    });
  };

  const handlePlayerSave = (player) => {
    const updatedPlayers = data.players.map((p) => {
      if (p === player) return player;
      return p;
    });
    setData({
      locations: data.locations,
      roads: data.roads,
      tradeRoutes: data.tradeRoutes,
      players: updatedPlayers.sort((a, b) =>
        a.playerName.localeCompare(b.playerName)
      ),
    });
  };

  const handleDrag = (location, roads) => {
    const updatedLocations = data.locations.map((l) => {
      if (l === location) return location;
      return l;
    });
    const updatedRoads = data.roads.map((r) => {
      const changedRoad = roads.find((changedRoad) => r === changedRoad);
      if (r === changedRoad) return changedRoad;
      return r;
    });
    setData({
      locations: updatedLocations.sort((a, b) => a.name.localeCompare(b.name)),
      roads: updatedRoads.sort((a, b) => a.name.localeCompare(b.name)),
      tradeRoutes: data.tradeRoutes,
      players: data.players,
    });
  };

  return (
    <div>
      <div className="topBar flex">
        <span className="menuButton" onClick={() => setShowMenu(!showMenu)}>
          <FontAwesomeIcon icon={faBars} />
        </span>
        <h1>Ash Wastes Campaign Tool</h1>
      </div>

      {isAdmin && (
        <div className="setupBar flex">
          <label className="players">
            <span>Players</span>
            <input
              value={numberOfPlayers}
              onChange={(e) => setNumberOfPlayers(e.target.value)}
              type="number"
              min="4"
              max="20"
            />
          </label>
          <label className="locations">
            <span>Locations</span>
            <input
              value={numberOfLocations}
              onChange={(e) => setNumberOfLocations(e.target.value)}
              type="number"
              min="4"
              max="20"
            />
          </label>
          <label className="tradeRoutes">
            <span>Trade Routes</span>
            <input
              value={numberOfTradeRoutes}
              onChange={(e) => setNumberOfTradeRoutes(e.target.value)}
              type="number"
              min="4"
              max="20"
            />
          </label>
          <button className="generateButton" onClick={generate}>
            Generate
          </button>

          {publicId && (
            <FacebookShareButton
              url={`https://ashwastes.co.uk/${publicId}`}
              quote={"My Ash Wastes Campaign"}
            >
              <FacebookIcon size={28} round />
            </FacebookShareButton>
          )}
        </div>
      )}

      <nav className={showMenu ? "open" : ""}>
        <ul>
          <li onClick={() => menuSelect("map")}>
            <FontAwesomeIcon icon={faMap} /> Map
          </li>
          <li onClick={() => menuSelect("locations")}>
            <FontAwesomeIcon icon={faLocationDot} /> Locations
          </li>
          <li onClick={() => menuSelect("roads")}>
            <FontAwesomeIcon icon={faRoad} /> Roads
          </li>
          <li onClick={() => menuSelect("tradeRoutes")}>
            <FontAwesomeIcon icon={faRoute} /> Trade Routes
          </li>
          <li onClick={() => menuSelect("players")}>
            <FontAwesomeIcon icon={faUsers} /> Players
          </li>
          {isAdmin && (
            <>
              <li className="divider"></li>
              <li onClick={() => saveMap()}>
                <FontAwesomeIcon icon={faFloppyDisk} /> Save
              </li>
              <li onClick={() => saveImage()}>
                <FontAwesomeIcon icon={faImage} /> Save Image
              </li>
            </>
          )}
        </ul>
      </nav>

      <section className="content">
        {apiError && <p>{apiError}</p>}
        {selectedTab === "map" && (
          <Map
            className="map"
            locations={data.locations}
            roads={data.roads}
            players={data.players}
            onDrag={handleDrag}
            isAdmin={isAdmin}
          />
        )}
        {selectedTab === "locations" && (
          <Locations
            locations={data.locations}
            onLocationSave={handleLocationSave}
            isAdmin={isAdmin}
          />
        )}
        {selectedTab === "roads" && (
          <Roads
            roads={data.roads}
            onRoadSave={handleRoadSave}
            isAdmin={isAdmin}
            players={data.players}
          />
        )}
        {selectedTab === "tradeRoutes" && (
          <TradeRoutes
            tradeRoutes={data.tradeRoutes}
            onTradeRouteSave={handleTradeRouteSave}
            isAdmin={isAdmin}
          />
        )}
        {selectedTab === "players" && (
          <Players
            players={data.players}
            locations={data.locations}
            roads={data.roads}
            tradeRoutes={data.tradeRoutes}
            onPlayerSave={handlePlayerSave}
            isAdmin={isAdmin}
          />
        )}
      </section>

      {loading && (
        <div className="loading">
          <div className="spinner">
            <PuffLoader color="#36d7b7" />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
