import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faUsers } from "@fortawesome/free-solid-svg-icons";

import Modal from "../modal/Modal";
import Graph from "../../classes/graph";

import "./Players.scss";

const Players = ({
  players,
  onPlayerSave,
  locations,
  roads,
  tradeRoutes,
  isAdmin,
}) => {
  const [editId, setEditId] = useState(null);
  const [playerName, setPlayerName] = useState(null);
  const [gangName, setGangName] = useState(null);
  const [faction, setFaction] = useState(null);
  const [customFaction, setCustomFaction] = useState(null);
  const [alignment, setAlignment] = useState(null);

  const handleEdit = (player) => {
    setPlayerName(player.playerName);
    setGangName(player.gangName);
    setFaction(player.faction);
    setCustomFaction(player.customFaction);
    setAlignment(player.alignment);
    setEditId(player.id);
  };

  const handleClose = () => {
    setPlayerName(null);
    setGangName(null);
    setFaction(null);
    setCustomFaction(null);
    setAlignment(null);
    setEditId(null);
  };

  const handleSave = () => {
    const player = players.find((t) => t.id === editId);
    player.playerName = playerName;
    player.gangName = gangName;
    player.faction = faction;
    player.customFaction = customFaction;
    player.alignment = alignment;
    onPlayerSave(player);
    handleClose();
  };

  const verifyTradeRoutes = (player) => {
    const graph = new Graph();
    const controlledRoads = roads.filter(
      (road) => road.controllingPlayer === player.id
    );

    locations.forEach((location) => {
      graph.addVertex(location.id);
    });

    locations
      .flatMap((location) =>
        location.roadConnections
          .filter((connection) =>
            connection.roadIds.every((roadId) =>
              controlledRoads.some(
                (controlledRoad) => controlledRoad.id === roadId
              )
            )
          )
          .map((connection) => ({
            start: location.id,
            end: connection.otherLocationId,
          }))
      )
      .forEach((connection) => {
        graph.addEdge(connection.start, connection.end);
        graph.addEdge(connection.end, connection.start);
      });

    const controlledTradeRoutes = tradeRoutes.filter((tradeRoute) =>
      graph.dfs(tradeRoute.endLocation.id, tradeRoute.startLocation.id)
    );

    return controlledTradeRoutes;
  };

  return (
    <>
      <div className="players">
        <h2>
          <FontAwesomeIcon icon={faUsers} /> Players
        </h2>
        {players.length !== 0 && (
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Player Name</th>
                <th>Gang Name</th>
                <th>Faction</th>
                <th>Alignment</th>
                <th>Controlled Roads & Trade Routes</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {players.map((player, index) => (
                <tr key={index}>
                  <td>
                    <span
                      className="colorBlock"
                      style={{ backgroundColor: player.color }}
                    ></span>
                  </td>
                  <td>{player.playerName}</td>
                  <td>{player.gangName}</td>
                  <td>
                    {player.faction === "Other"
                      ? player.customFaction
                      : player.faction}
                  </td>
                  <td>{player.alignment}</td>
                  <td>
                    {roads
                      .filter((r) => r.controllingPlayer === player.id)
                      .map((road) => (
                        <div key={road.id}>
                          {road.name} <em>({road.income})</em>
                        </div>
                      ))}
                    {verifyTradeRoutes(player).map((tradeRoute) => (
                      <div key={tradeRoute.id}>
                        <strong>{tradeRoute.name}</strong>{" "}
                        <em>
                          (
                          {player.alignment === "Trader"
                            ? tradeRoute.tradingBonus
                            : tradeRoute.raidingBonus}
                          )
                        </em>
                      </div>
                    ))}
                  </td>
                  <td>
                    {isAdmin && (
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        title="edit"
                        onClick={() => handleEdit(player)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {players.length === 0 && <p>Generate a network to start</p>}
      </div>
      {editId !== null && (
        <Modal title="Edit Player" onSave={handleSave} onClose={handleClose}>
          <label className="field">
            <span>Player Name:</span>
            <input
              type="text"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
            />
          </label>
          <label className="field">
            <span>Gang Name:</span>
            <input
              type="text"
              value={gangName}
              onChange={(e) => setGangName(e.target.value)}
            />
          </label>
          <label className="field">
            <span>Faction:</span>
            <select
              value={faction}
              onChange={(e) => setFaction(e.target.value)}
            >
              <option value="Unknown">Unknown</option>
              <option value="House Escher">House Escher</option>
              <option value="House Goliath">House Goliath</option>
              <option value="House Orlock">House Orlock</option>
              <option value="House VanSaar">House VanSaar</option>
              <option value="House Delaque">House Delaque</option>
              <option value="House Cawdor">House Cawdor</option>
              <option value="Palentine Enforcers">Palentine Enforcers</option>
              <option value="Corpse Grider Cult">Corpse Grider Cult</option>
              <option value="Chaos Cult">Chaos Cult</option>
              <option value="Genestealer Cult">Genestealer Cult</option>
              <option value="Slave Ogryns">Slave Ogryns</option>
              <option value="Squat Prospectors">Squat Prospectors</option>
              <option value="Venators">Venators</option>
              <option value="Ash Wastes Nomads">Ash Wastes Nomads</option>
              <option value="Other">Other</option>
            </select>
            &nbsp;
            {faction === "Other" && (
              <input
                type="text"
                value={customFaction}
                onChange={(e) => setCustomFaction(e.target.value)}
              />
            )}
          </label>
          <label className="field">
            <span>Alignment:</span>
            <label>
              <input
                type="radio"
                value="Trader"
                name="Alignment"
                checked={alignment === "Trader"}
                onChange={(e) => setAlignment(e.target.value)}
              />
              Trader
            </label>
            <label>
              <input
                type="radio"
                value="Raider"
                name="Alignment"
                checked={alignment === "Raider"}
                onChange={(e) => setAlignment(e.target.value)}
              />
              Raider
            </label>
          </label>
        </Modal>
      )}
    </>
  );
};

export default Players;
