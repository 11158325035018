// returns a random integer between min an max
const random = (min, max) => {
  min = Math.floor(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// returns a random value from the given array
const selectRandom = (data) => {
  return data[random(0, data.length - 1)];
};

// returns a unique random value from the given array,
// compared to the values array
const selectUniqueRandom = (data, values) => {
  var value = selectRandom(data);
  var attempts = 0;
  while (values.some((v) => v === value) && attempts < 10) {
    value = selectRandom(data);
    attempts++;
  }
  return value;
};

// returns the distance between point1 and point 2
const calculateDistance = (point1, point2) => {
  var a = point1.x - point2.x;
  var b = point1.y - point2.y;

  return Math.sqrt(a * a + b * b);
};

const calculateAngle = (point1, point2) => {
  return Math.atan2(point2.y - point1.y, point2.x - point1.x);
};

const calculateMidPoint = (point1, point2) => {
  const midPoint = {
    x: (point1.x + point2.x) / 2,
    y: (point1.y + point2.y) / 2,
  };
  return midPoint;
};

export {
  calculateAngle,
  calculateDistance,
  calculateMidPoint,
  random,
  selectRandom,
  selectUniqueRandom,
};
