import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faPenToSquare,
  faRoad,
} from "@fortawesome/free-solid-svg-icons";

import Modal from "../modal/Modal";
import { sampleRoadNames } from "../../classes/sampleNames";
import { selectRandom } from "../../classes/helpers";

import "./Roads.scss";

const Roads = ({ roads, onRoadSave, isAdmin, players }) => {
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState(null);
  const [controllingPlayer, setControllingPlayer] = useState(null);
  const [region, setRegion] = useState(null);
  const [income, setIncome] = useState(null);

  const generateName = () => {
    switch (region) {
      case "Near Wastes":
      default:
        setRegion(selectRandom(sampleRoadNames.nearWastes));
        break;
      case "Deep Wastes":
        setRegion(selectRandom(sampleRoadNames.deepWastes));
        break;
      case "Wild Wastes":
        setRegion(selectRandom(sampleRoadNames.wildWastes));
        break;
    }
  };

  const handleEdit = (road) => {
    setName(road.name);
    setRegion(road.region);
    setIncome(road.income);
    setControllingPlayer(
      road.controllingPlayer === null ? "" : road.controllingPlayer
    );
    setEditId(road.id);
  };

  const handleClose = () => {
    setName(null);
    setRegion(null);
    setIncome(null);
    setControllingPlayer(null);
    setEditId(null);
  };

  const handleSave = () => {
    const road = roads.find((r) => r.id === editId);
    road.name = name;
    road.region = region;
    road.income = income;
    road.controllingPlayer =
      controllingPlayer === "" ? null : parseInt(controllingPlayer);
    onRoadSave(road);
    handleClose();
  };

  return (
    <>
      <div className="roads">
        <h2>
          <FontAwesomeIcon icon={faRoad} /> Roads
        </h2>
        {roads.length !== 0 && (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Region</th>
                <th>Income</th>
                <th>Controlling Player</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {roads.map((road, index) => (
                <tr key={index}>
                  <td>{road.name}</td>
                  <td>{road.region}</td>
                  <td>{road.income}</td>
                  <td>
                    {players
                      .filter((p) => p.id === road.controllingPlayer)
                      .map((p) => (
                        <span key={p.id}>
                          {p.gangName} <em>({p.playerName})</em>
                        </span>
                      ))}
                  </td>
                  <td>
                    {isAdmin && (
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        title="edit"
                        onClick={() => handleEdit(road)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {roads.length === 0 && <p>Generate a network to start</p>}
      </div>
      {editId !== null && (
        <Modal title="Edit Road" onSave={handleSave} onClose={handleClose}>
          <label className="field">
            <span>Name:</span>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faLightbulb}
              className="addOn"
              onClick={generateName}
            />
          </label>
          <label className="field">
            <span>Region:</span>
            <select value={region} onChange={(e) => setRegion(e.target.value)}>
              <option value="Near Wastes">Near Wastes</option>
              <option value="Deep Wastes">Deep Wastes</option>
              <option value="Wild Wastes">Wild Wastes</option>
            </select>
          </label>
          <label className="field">
            <span>Income:</span>
            <input
              type="text"
              value={income}
              onChange={(e) => setIncome(e.target.value)}
            />
          </label>
          <label className="field">
            <span>Controlling Player:</span>
            <select
              value={controllingPlayer}
              onChange={(e) => setControllingPlayer(e.target.value)}
            >
              <option value="">Uncontrolled</option>
              {players.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.gangName} ({p.playerName})
                </option>
              ))}
            </select>
          </label>
        </Modal>
      )}
    </>
  );
};

export default Roads;
