import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import App from "./App";

import "./index.scss";

const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);

const steps = [
  {
    selector: ".players",
    content:
      "Select the number of players in your campaign to gnerate the correct number of roads",
  },
  {
    selector: ".locations",
    content:
      "Select the number of number of locations to control how many roads are on each link",
  },
  {
    selector: ".tradeRoutes",
    content: "Select the number of number of trade routes you want to generate",
  },
  {
    selector: ".generateButton",
    content:
      "When you are ready, press Generate to create a map.  Click this button as many times as you like until you generate a map you like.",
  },
  {
    selector: ".map",
    content:
      "You can drag the locations around for a better layout if you wish.",
  },
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <TourProvider
      steps={steps}
      afterOpen={disableBody}
      beforeClose={enableBody}
    >
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </TourProvider>
  </React.StrictMode>
);
