import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faPenToSquare,
  faRoute,
} from "@fortawesome/free-solid-svg-icons";

import Modal from "../modal/Modal";
import { sampleTradeRouteNames } from "../../classes/sampleNames";
import { selectRandom } from "../../classes/helpers";

import "./TradeRoutes.scss";

const TradeRoutes = ({ tradeRoutes, onTradeRouteSave, isAdmin }) => {
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState(null);
  const [tradingBonus, setTradingBonus] = useState(null);
  const [raidingBonus, setRaidingBonus] = useState(null);

  const generateName = () => {
    setName(selectRandom(sampleTradeRouteNames));
  };

  const handleEdit = (tradingRoute) => {
    setName(tradingRoute.name);
    setTradingBonus(tradingRoute.tradingBonus);
    setRaidingBonus(tradingRoute.raidingBonus);
    setEditId(tradingRoute.id);
  };

  const handleClose = () => {
    setName(null);
    setTradingBonus(null);
    setRaidingBonus(null);
    setEditId(null);
  };

  const handleSave = () => {
    const tradeRoute = tradeRoutes.find((t) => t.id === editId);
    tradeRoute.name = name;
    tradeRoute.tradingBonus = tradingBonus;
    tradeRoute.raidingBonus = raidingBonus;
    onTradeRouteSave(tradeRoute);
    handleClose();
  };

  return (
    <>
      <div className="tradeRoutes">
        <h2>
          <FontAwesomeIcon icon={faRoute} /> TradeRoutes
        </h2>
        {tradeRoutes.length !== 0 && (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Route</th>
                <th>Trading Bonus</th>
                <th>Raiding Bonus</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tradeRoutes.map((tradeRoute, index) => (
                <tr key={index}>
                  <td>{tradeRoute.name}</td>
                  <td>
                    {tradeRoute.startLocation.name}&rarr;
                    {tradeRoute.endLocation.name}
                  </td>
                  <td>{tradeRoute.tradingBonus}</td>
                  <td>{tradeRoute.raidingBonus}</td>
                  <td>
                    {isAdmin && (
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        title="edit"
                        onClick={() => handleEdit(tradeRoute)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {tradeRoutes.length === 0 && <p>Generate a network to start</p>}
      </div>
      {editId !== null && (
        <Modal
          title="Edit Trade Route"
          onSave={handleSave}
          onClose={handleClose}
        >
          <label className="field">
            <span>Name:</span>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faLightbulb}
              className="addOn"
              onClick={generateName}
            />
          </label>
          <label className="field">
            <span>Trading Bonus:</span>
            <input
              type="text"
              value={tradingBonus}
              onChange={(e) => setTradingBonus(e.target.value)}
            />
          </label>
          <label className="field">
            <span>Raiding Bonus:</span>
            <input
              type="text"
              value={raidingBonus}
              onChange={(e) => setRaidingBonus(e.target.value)}
            />
          </label>
        </Modal>
      )}
    </>
  );
};

export default TradeRoutes;
