import { useEffect, useState, useRef } from "react";

import { calculateAngle } from "../../classes/helpers";
import { render } from "../../classes/renderer";

import "./Map.scss";

const LOCATION_PADDING = 20;

const Map = ({ locations, roads, onDrag, isAdmin, players }) => {
  const [cursorLocation, setCursorLocation] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [draggingLocation, setDraggingLocation] = useState(null);
  const [showOwnership, setShowOwnership] = useState(false);
  const [cursorRoad, setCursorRoad] = useState(null);
  const mapContainer = useRef();
  const canvas = useRef();

  useEffect(() => {
    if (canvas.current && locations.length > 0)
      render(canvas.current, locations, roads, players, showOwnership);
  }, [canvas, locations, roads, players, showOwnership]);

  useEffect(() => {
    if (isAdmin) {
      var location = null;
      locations.forEach((l) => {
        if (
          cursorLocation.x > l.point.x - 20 &&
          cursorLocation.x < l.point.x + 20 &&
          cursorLocation.y > l.point.y - 20 &&
          cursorLocation.y < l.point.y + 20
        ) {
          location = l;
          return false;
        }
      });
      canvas.current.style.cursor = location ? "move" : "default";
    }

    let cursorRoad = null;
    roads.forEach((r) => {
      const dist = PointDistanceToVector(
        cursorLocation.x,
        cursorLocation.y,
        r.startPoint.x,
        r.startPoint.y,
        r.endPoint.x,
        r.endPoint.y
      );
      if (dist < 5) {
        cursorRoad = r;
        return;
      }
    });
    setCursorRoad(cursorRoad);

    if (draggingLocation) {
      draggingLocation.point.x = cursorLocation.x;
      draggingLocation.point.y = cursorLocation.y;
      onDrag(draggingLocation, updateLocationRoads(draggingLocation));
    }
  }, [cursorLocation, dragging, draggingLocation]);

  const updateLocationRoads = (location) => {
    const updatedRoads = [];
    location.roadConnections.forEach((connection) => {
      const otherLocation = locations.find(
        (l) => l.id === connection.otherLocationId
      );
      const angle = calculateAngle(location.point, otherLocation.point);
      const connectionStartPoint = {
        x: location.point.x + LOCATION_PADDING * Math.cos(angle),
        y: location.point.y + LOCATION_PADDING * Math.sin(angle),
      };
      const connectionEndPoint = {
        x: otherLocation.point.x - LOCATION_PADDING * Math.cos(angle),
        y: otherLocation.point.y - LOCATION_PADDING * Math.sin(angle),
      };

      var startPoint = connectionStartPoint;
      connection.roadIds.forEach((roadId, index) => {
        const road = roads.find((r) => r.id === roadId);
        const endPoint = {
          x:
            connectionStartPoint.x +
            ((connectionEndPoint.x - connectionStartPoint.x) /
              connection.roadIds.length) *
              (index + 1),
          y:
            connectionStartPoint.y +
            ((connectionEndPoint.y - connectionStartPoint.y) /
              connection.roadIds.length) *
              (index + 1),
        };

        road.startPoint = startPoint;
        road.endPoint = endPoint;
        updatedRoads.push(road);
        startPoint = endPoint;
      });
    });

    return updatedRoads;
  };

  const handleMouseMove = (e) => {
    if (!mapContainer.current) return;
    setCursorLocation({
      x: e.pageX - mapContainer.current.offsetLeft,
      y: e.pageY - mapContainer.current.offsetTop,
    });
  };

  const handleMouseDown = (e) => {
    if (e.button !== 0 || !isAdmin) return;
    setDragging(true);
    locations.forEach((l) => {
      if (
        cursorLocation.x > l.point.x - 20 &&
        cursorLocation.x < l.point.x + 20 &&
        cursorLocation.y > l.point.y - 20 &&
        cursorLocation.y < l.point.y + 20
      ) {
        setDraggingLocation(l);
        return false;
      }
    });
  };

  const handleMouseUp = () => {
    if (!isAdmin) return;
    setDragging(false);
    setDraggingLocation(null);
  };

  const PointDistanceToVector = (x, y, x1, y1, x2, y2) => {
    var A = x - x1;
    var B = y - y1;
    var C = x2 - x1;
    var D = y2 - y1;

    var dot = A * C + B * D;
    var len_sq = C * C + D * D;
    var param = -1;
    if (len_sq !== 0)
      //in case of 0 length line
      param = dot / len_sq;

    var xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    } else if (param > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    var dx = x - xx;
    var dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
  };

  return (
    <div className="map" ref={mapContainer}>
      {cursorRoad && (
        <div
          className="tooltip"
          style={{
            left: cursorLocation.x,
            top: cursorLocation.y,
            position: "absolute",
          }}
        >
          <p>
            <strong>{cursorRoad.name}</strong> <em>{cursorRoad.income}</em>
          </p>
          <p>
            {players
              .filter((p) => p.id === cursorRoad.controllingPlayer)
              .map((p) => (
                <span key={p.id}>
                  {p.gangName} <em>({p.playerName})</em>
                </span>
              ))}
          </p>
        </div>
      )}

      <canvas
        id="map"
        ref={canvas}
        width="1080"
        height="1080"
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
      {locations.length > 0 && (
        <div className="showOwnership">
          <label>
            <input
              type="checkbox"
              checked={showOwnership}
              onChange={() => setShowOwnership(!showOwnership)}
            />
            <span>Show Ownership</span>
          </label>
        </div>
      )}
    </div>
  );
};

export default Map;
