import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

import LocationIcon from "./LocationIcon";
import Modal from "../modal/Modal";
import { sampleLocationNames } from "../../classes/sampleNames";
import { selectRandom } from "../../classes/helpers";

import "./Locations.scss";

const Locations = ({ locations, onLocationSave, isAdmin }) => {
  const [editId, setEditId] = useState(null);
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);

  const generateName = () => {
    setName(selectRandom(sampleLocationNames[type]));
  };

  const handleEdit = (location) => {
    setName(location.name);
    setType(location.type);
    setEditId(location.id);
  };

  const handleClose = () => {
    setName(null);
    setType(null);
    setEditId(null);
  };

  const handleSave = () => {
    const location = locations.find((l) => l.id === editId);
    location.name = name;
    location.type = type;
    onLocationSave(location);
    handleClose();
  };

  return (
    <>
      <div className="locations">
        <h2>
          <FontAwesomeIcon icon={faLocationDot} /> Locations
        </h2>
        {locations.length !== 0 && (
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {locations.map((location, index) => (
                <tr key={index}>
                  <td>
                    <LocationIcon type={location.type} />
                  </td>
                  <td>{location.name}</td>
                  <td>
                    {isAdmin && (
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        title="edit"
                        onClick={() => handleEdit(location)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {locations.length === 0 && <p>Generate a network to start</p>}
      </div>
      {editId !== null && (
        <Modal title="Edit Location" onSave={handleSave} onClose={handleClose}>
          <label className="field">
            <span>Name:</span>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faLightbulb}
              className="addOn"
              onClick={generateName}
            />
          </label>
          <label className="field">
            <span>Type:</span>
            <select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="hive">Hive City</option>
              <option value="city">Major Settlement</option>
              <option value="town">Minor Settlement</option>
            </select>
          </label>
        </Modal>
      )}
    </>
  );
};

export default Locations;
