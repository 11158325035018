const playerColors = [
  "#8D6BFF",
  "#ED30FF",
  "#D670FF",
  "#FF474D",
  "#FF8F54",
  "#FFC96D",
  "#FFFF77",
  "#C4FF66",
  "#83FF6D",
  "#6DFF99",
  "#70FFD1",
  "#6DFFFC",
  "#6BCBFF",
  "#7CA8FF",
  "#C7FFC4",
  "#EDBCFF",
  "#FFC6CA",
  "#FF0793",
  "#006EFF",
  "#FFFFFF",
];
export default playerColors;
