import "./Modal.scss";

const Modal = ({ title, onSave, onClose, children }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>{title}</h2>
        <section>{children}</section>
        <footer>
          <button onClick={onSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </footer>
      </div>
    </div>
  );
};

export default Modal;
