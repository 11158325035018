import { selectRandom, selectUniqueRandom } from "./helpers";
import { sampleTradeRouteNames } from "./sampleNames";

const tradingBonuses = [
  { cost: 6, value: "5D6x10 credits" },
  { cost: 5, value: "4D6x10 credits" },
  {
    cost: 5,
    value: "Add a Bounty Hunter for free until the next campaign week",
  },
  { cost: 4, value: "3D6x10 credits" },
  {
    cost: 3,
    value:
      "Half the cost of hiring Hangers-On rounds up to the nearest 5 credits",
  },
  { cost: 3, value: "2D6x10 credits" },
  {
    cost: 3,
    value: "Increase Rep by 1",
  },
  {
    cost: 2,
    value: "Reduce Rarity of all items in the Trading Post by 2",
  },
  { cost: 2, value: "D6x10 credits" },
];

const raidingBonuses = [
  { cost: 6, value: "5D6x10 credits" },
  { cost: 5, value: "4D6x10 credits" },
  {
    cost: 5,
    value:
      "Add an outlaw Dramatis Personae for free until the next campaign week",
  },
  { cost: 4, value: "3D6x10 credits" },
  {
    cost: 3,
    value:
      "Half the cost of hiring Hangers-On rounds up to the nearest 5 credits",
  },
  { cost: 3, value: "2D6x10 credits" },
  {
    cost: 3,
    value: "Add a Scum for free until the next campaign week",
  },
  {
    cost: 2,
    value: "Reduce Legality of all items in the Trading Post by 2",
  },
  { cost: 2, value: "D6x10 credits" },
];

const generateTradeRoutes = (locations, numberOfTradeRoutes) => {
  const graph = locations.reduce(
    (a, v) => ({ ...a, [v.id.toString()]: v.connections }),
    {}
  );

  const allRoutes = locations
    .map((startLocation) =>
      locations
        .filter((endLocation) => endLocation !== startLocation)
        .map((endLocation) => ({
          startLocation: startLocation,
          endLocation: endLocation,
          cost: dijkstra(
            graph,
            startLocation.id.toString(),
            endLocation.id.toString()
          ),
        }))
        .filter((tradeRoute) => tradeRoute.cost >= 2 && tradeRoute.cost <= 6)
    )
    .flat(1)
    .sort((a, b) => {
      if (a.cost === b.cost) {
        return Math.random() > 0.5 ? 1 : -1;
      }
      return b.cost - a.cost;
    });

  var dist = Math.floor(allRoutes.length / numberOfTradeRoutes);
  var tradeRoutes = [];
  for (var i = 0; i < allRoutes.length; i = i + dist) {
    tradeRoutes.push(allRoutes[i]);
  }

  tradeRoutes.forEach((tradeRoute, index) => {
    tradeRoute.id = index;
    tradeRoute.name = selectUniqueRandom(
      sampleTradeRouteNames,
      tradeRoutes.map((t) => t.name)
    );
    tradeRoute.tradingBonus = selectRandom(
      tradingBonuses
        .filter((x) => x.cost === Math.min(tradeRoute.cost, 6))
        .map((x) => x.value)
    );
    tradeRoute.raidingBonus = selectRandom(
      raidingBonuses
        .filter((x) => x.cost === Math.min(tradeRoute.cost, 6))
        .map((x) => x.value)
    );
  });

  return tradeRoutes.sort((a, b) => a.name.localeCompare(b.name));
};

const formatGraph = (g) => {
  const tmp = {};
  Object.keys(g).forEach((k) => {
    const obj = g[k];
    const arr = [];
    Object.keys(obj).forEach((v) => arr.push({ vertex: v, cost: obj[v] }));
    tmp[k] = arr;
  });
  return tmp;
};

const dijkstra = (graph, start, end) => {
  var map = formatGraph(graph);

  var visited = [];
  var unvisited = [start];
  var shortestDistances = { [start]: { vertex: start, cost: 0 } };

  var vertex;
  while ((vertex = unvisited.shift())) {
    // Explore unvisited neighbors
    var neighbors = map[vertex].filter((n) => !visited.includes(n.vertex));

    // Add neighbors to the unvisited list
    unvisited.push(...neighbors.map((n) => n.vertex));

    var costToVertex = shortestDistances[vertex].cost;

    for (let { vertex: to, cost } of neighbors) {
      var currCostToNeighbor =
        shortestDistances[to] && shortestDistances[to].cost;
      var newCostToNeighbor = costToVertex + cost;
      if (
        currCostToNeighbor === undefined ||
        newCostToNeighbor < currCostToNeighbor
      ) {
        // Update the table
        shortestDistances[to] = { vertex, cost: newCostToNeighbor };
      }
    }

    visited.push(vertex);
  }

  return shortestDistances[end].cost;
};

export { generateTradeRoutes };
