import {
  calculateAngle,
  calculateMidPoint,
  calculateDistance,
} from "./helpers";

var backgroundImageLoaded = false;
var backgroundImage;

const render = (canvas, locations, roads, players, showOwnership) => {
  const ctx = canvas.getContext("2d");

  if (backgroundImageLoaded) {
    renderMap(ctx, locations, roads, players, showOwnership);
  } else {
    backgroundImage = new Image();
    backgroundImage.onload = () => {
      backgroundImageLoaded = true;
      renderMap(ctx, locations, roads, players, showOwnership);
    };
    backgroundImage.src = `${process.env.PUBLIC_URL}/mapTexture.png`;
  }
};

const renderMap = (ctx, locations, roads, players, showOwnership) => {
  ctx.drawImage(backgroundImage, 0, 0);

  drawRoads(ctx, roads, players, showOwnership);
  drawHives(ctx, locations);
  drawCities(ctx, locations);
  drawTowns(ctx, locations);

  // Key
  drawRoad(ctx, 20, 30, 100, 30, 2, "#492A0E");
  drawText(ctx, "Near Wastes", 110, 30, "left", 15);
  drawRoad(ctx, 20, 60, 100, 60, 2, "#724115", [8, 5]);
  drawText(ctx, "Deep Wastes", 110, 60, "left", 15);
  drawRoad(ctx, 20, 90, 100, 90, 2, "#9E5B1E", [2, 4]);
  drawText(ctx, "Wild Wastes", 110, 90, "left", 15);
};

const drawRoads = (ctx, roads, players, showOwnership) => {
  roads.forEach((road) => {
    var color = "#492A0E";
    var pattern = [];
    if (road.region === "Deep Wastes") {
      color = "#724115";
      pattern = [8, 5];
    } else if (road.region === "Wild Wastes") {
      color = "#9E5B1E";
      pattern = [2, 4];
    }

    if (road.controllingPlayer !== null && showOwnership) {
      var player = players.find((p) => p.id === road.controllingPlayer);
      drawRoad(
        ctx,
        road.startPoint.x,
        road.startPoint.y,
        road.endPoint.x,
        road.endPoint.y,
        6,
        player.color,
        []
      );
    }
    drawRoad(
      ctx,
      road.startPoint.x,
      road.startPoint.y,
      road.endPoint.x,
      road.endPoint.y,
      3,
      color,
      pattern
    );
  });

  roads.forEach((road) => {
    const midPoint = calculateMidPoint(road.startPoint, road.endPoint);
    var angle = calculateAngle(road.startPoint, road.endPoint);
    if (Math.abs(angle) > Math.PI / 2) angle = angle - Math.PI;
    var roadLength = calculateDistance(road.startPoint, road.endPoint);

    ctx.font = "11px Georgia";
    var textWidth = ctx.measureText(road.name).width;
    if (textWidth + 10 > roadLength) {
      var words = road.name.split(" ");
      var line1 = "";
      var line2 = "";
      var foundFit = false;
      for (var i = 0; i < words.length; i++) {
        line1 = line1 + " " + words[i];
        line2 = words.slice(i + 1, words.length).join(" ");
        if (
          ctx.measureText(line1).width + 10 < roadLength &&
          ctx.measureText(line2).width + 10 < roadLength
        ) {
          drawText(ctx, line1, midPoint.x, midPoint.y, "center", 11, angle, -7);
          drawText(ctx, line2, midPoint.x, midPoint.y, "center", 11, angle, 7);
          foundFit = true;
          break;
        }
      }
      if (!foundFit) {
        drawText(
          ctx,
          road.name,
          midPoint.x + 3,
          midPoint.y,
          "left",
          11,
          Math.PI / -5
        );
      }
    } else {
      drawText(ctx, road.name, midPoint.x, midPoint.y, "center", 11, angle, -7);
    }
  });
};

const drawDot = (ctx, x, y) => {
  ctx.beginPath();
  ctx.fillStyle = "black";
  ctx.strokeStyle = "black";
  ctx.arc(x, y, 3, 0, 2 * Math.PI, false);
  ctx.fill();
  ctx.stroke();
};

const drawRoad = (ctx, x1, y1, x2, y2, width, color, dashPattern = []) => {
  ctx.beginPath();
  ctx.lineWidth = width;
  ctx.strokeStyle = color;
  ctx.setLineDash(dashPattern);
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();

  drawDot(ctx, x1, y1);
  drawDot(ctx, x2, y2);
};

const drawText = (ctx, text, x, y, align, fontSize, angle = 0, yOffset = 0) => {
  const lineHeight = fontSize * 0.75;
  const padding = 3;
  ctx.font = `${fontSize}px Georgia`;
  var textWidth = ctx.measureText(text).width;
  ctx.save();
  ctx.translate(x, y);
  ctx.rotate(angle);
  ctx.fillStyle = "rgba(222, 193, 163, 0.5)";
  const alignAdjust =
    align === "left" ? 0 : align === "right" ? textWidth : textWidth / 2;
  ctx.fillRect(
    0 - alignAdjust - padding,
    0 - lineHeight / 2 - padding + yOffset,
    textWidth + padding * 2,
    lineHeight + padding * 2
  );
  ctx.fillStyle = "black";
  ctx.textAlign = align;
  ctx.fillText(text, 0, lineHeight / 2 + yOffset);
  ctx.restore();
};

const drawHives = (ctx, locations) => {
  const image = new Image();
  image.onload = () => {
    locations
      .filter((l) => l.type === "hive")
      .forEach((location) => {
        ctx.drawImage(
          image,
          location.point.x - 20,
          location.point.y - 20,
          40,
          40
        );
        drawText(
          ctx,
          location.name,
          location.point.x,
          location.point.y + 30,
          "center",
          18
        );
      });
  };
  image.src =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAKASURBVFhH7ZdNy0xhGIBnhiGvz1AUIuRbCQnZ+ShLVlYWisjGzubdW1Bv2fgFFra2yMZHFpQFKSELG1JiQYlxXWdMjZln5pznnJkzZ+Gqq3fO6Z0z97nPc9/PfWoj5iQua3+sHkvxHZ5LjipGAy9iC5/jfKwMs/A0vkAD1DO4CCuB2TK439gJ8COex4njo92P37ATXMeXuAQnigHcx1/YG+AXvIB1nIcTYQ/+xN7gOj7FjbgJc+EjKsI+nN3+GGQ3TuOq5KhktuMjDGWuWzNsVeciTwabuAWv4AFPpGCGV7Y/lsNWtEJ/YChjIR/iXowmTwbf4iecmxxl4yAexikctmb7iA3QXcMfWZMcxeF3zeLi5CgjfimGdWg23NpcizGsRwvmFX72xDg4jm8wtM7S/IpW/SG0eWsqsY94M5qJPCxEq34FWtWZ1nDWAN2qnPNOJEfFWI02btdyKlnW4HL0YjcwV6vowcz5yG1VDhlDyZLBI3gMo9rDEJx+vNEFOJKZ8TJaeaGJJY9ex0nnFNobh5Ilgw6iFkdsQQ3C69gLXYtmcyhpP2oriO2VWbGn7sJCN+68dwtDj6qo9sXXGLWz9HIJQxcfpddw4NaZll4zOG7MoINtNDbl7xi661H6rF6vX8dMW1+HbXgXQxcchzbsndhH7yO2Yn3PdShN7VEjxKZtX+yjN0DfM56g5V82/nYf3QE6pZzFDZj7JacAj//+/YfuJuycNoPuuY5GZTPVaDSONptNJ6cPrVbLAk1Yi1fxAYYWcKlSzPoeZwh4B+dq97r/oWLedg3OwapST9tJJs7/AItigFF7YMkkG/RNdGoZV6BeN/SS7+ivg6BH1+78AVQnMhJxjhNeAAAAAElFTkSuQmCC";
};

const drawCities = (ctx, locations) => {
  const image = new Image();
  image.onload = () => {
    locations
      .filter((l) => l.type === "city")
      .forEach((location) => {
        ctx.drawImage(
          image,
          location.point.x - 15,
          location.point.y - 15,
          30,
          30
        );
        drawText(
          ctx,
          location.name,
          location.point.x,
          location.point.y + 25,
          "center",
          15
        );
      });
  };
  image.src =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAKsSURBVFhH7Zjdqw1RGMb37G0GR5Sv8hkdXxFRilBcUjr5KOlcoOiUP4ALSbmRGzrFP8AFnbiRW0lu3biUKLk47kn52sf2e2bNaLf3zKyZOWtmUp76Ne9a7Zl59pq13nfNtP5rWF5ELnWiY11aCI9hK7xQh03t6FhWS2CuCXPJh/0gg7k0W4N34bQJc2sGFpmwGu2E83AVfsJ7mIBDYJNG/BN8hi3qqELXoJfAFNgUG9Tvr6vDpjKP+Hd0HJQenU0yFmsctGgyNds5WFSjEJiwtRkuwNKwlaK6DV6CfkOTcNSEyarbYNL9MpO2S4OVpA6XBneBVqlTuTS4GnaY0J1cGtRcOgPxKo2l8lb6Pi4NSip7q0wYSte/B3vDVgm5NhgnYu2S5oBGdSNYE3Kaihq8Aqq7WVKl2QMHQdVFpvNUmUQVNfgdfpgwVTJzAk6BRm4xOF88WdIoalSS+AIqYa/hDRyAXzANm+AhDJ5zDlJVZg5mnaMbbgNtSEc9z7vBUfNRC+c56NEXkutF0oXDoF120Ov1tEeMS9la2GDC/HJtcD6MmdCNqjCoiuJMNoPKZVqJ88JWA7IZPAJvQdv8WGk76rLKzJE2gyOwEpapEQTBCCuzdNlK0cV2u30yiodkMxiP1kyn0/G73e4UK/NY1OdKypX7TFhA/CtN+Dug3PaSkVPK+Bq1ncK1P8Bx4vzihDUc/hqifR8qMRhxGfIJI6qjT0Av5kkXqwK9UA0p8eMRBs9y0CthnR+X3jGtxn3fX0A8zVz/ZrpbrXVwCx7BGD9agcGbxEn/skrCp8W9xUeYxMt2+sIiHv9IO49XcLuvr0meKs30v0OociyH9WGreXlJeVDmUhNn3bIl6sb1TxjM/UG7AXky9wB2q6GeCqTr6uV9UNrFZO1kyMGtZ38A40Dxr5hFdkQAAAAASUVORK5CYII=";
};

const drawTowns = (ctx, locations) => {
  const image = new Image();
  image.onload = () => {
    locations
      .filter((l) => l.type === "town")
      .forEach((location) => {
        ctx.drawImage(
          image,
          location.point.x - 10,
          location.point.y - 20,
          20,
          20
        );
        drawText(
          ctx,
          location.name,
          location.point.x,
          location.point.y + 10,
          "center",
          13
        );
      });
  };
  image.src =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAKkSURBVFhH7ZfNaxNBHIbztUlsMUSqYBFsoPUgiKgtfiBVRBQRiqJUFKSH9h/oRW9Cb70ognjUk4gonjyJ0oMnwYtePangf2AhYMzG552dDTHOJmTN1hb2gZeZ38zs7DsfO5NkUlJSUlJSUlK2NHmbJoln05ZNNxU59BSdMFEM1EGSZNEU2m6iGCRtUMsqNU0Ug6QNVtEOdNBEG02pVBopl8tFG7o4jH6hT4VCIfxYkiWXy3n5fH4eLWWz2ffoOcV7g9o/oW6VREv8g/wZU7gBVNB3FO4v6S4yVCqVAoM4Z8OXyLTB4A1TMiBx96CWrRN9rRmWsVqv1x8zuyuY3I2pA6Y24KRNB2JYH4mPualms/m60WgskLagiiZsfYb8fpvtRKuxLci66WewgHSGlU0UATM1i6k1TBxT7Pt+ixk8qv1qGgBtJtBFG4Ysodkg66afwQvoM7pjIuClekbG28gY6vxQfAyfx2j7KqV+Ei3bMET9mO0RRT+DI2gc7VTged4oL7lHdo/iHshYz76ZzRmS6+gWY75qCh30M+jbtMnG95iVZxhctGW9mEZzQdYN/ewjOYLOouMqGwhGpc37AOmYeMeIT5Ou2ziu3qAMg52hv49hOfkv6LLquomcQUY4RhLO1im0SCdBFB8ZUt/j6JApAfI1O6N/4TSIkXkeeEi2fY0RL6BRG8Zlmr6vIddIjfluomZQe+gSGvb9OcYgH+Fv0uGxxrZ6UiwWb7IFtHptdJjqqnqB5mikGyC8Q5PQOu+47Sj/qZR3S9/QfdqZm2jNNpAa6APSUdL58DCla/JrV1mUXmmJO38u6eDchWomSgadke0rsA9Z1x6UuStB9v8TecxsFraEwX8+fRPEHEb636pzLymj6td1nuqfXq9/e3xMmbe/AeLm7P9a63niAAAAAElFTkSuQmCC";
};

export { render };
