const sampleLocationNames = {
  town: [
    "Forgottenstorm Watch",
    "Valorweb Base",
    "Forgottensea Bastille",
    "Eternal Camp",
    "Shadow Camp",
    "Death Front",
    "Desolation Terminal",
    "Aaru Cluster",
    "The Nether",
    "The Verdicts",
    "Withermarsh",
    "The Prospects",
    "Netherville",
    "Bilgewaters",
    "The Boons",
    "The Confines",
    "Necroshire",
    "The Mopes",
    "Direfall",
    "Taintside",
    "Perishton",
    "The Blanks",
    "The Dumps",
    "Defectown",
    "Beggar's End",
    "Grimburg",
    "Taintside",
    "Blightown",
    "Endsby",
    "Vilewaters",
    "Killmoor",
    "The Skids",
    "Blackridge",
    "Miraclesfield",
    "The Projects",
    "Necrotown",
    "Sumpside Village",
    "Corpseville",
    "Enclave Outpost",
    "Outlaw's Outpost",
    "Purgatory Point",
    "Rat's Nest",
    "Scavenger's Station",
    "Sludge Town",
    "The Boneyard",
    "The Deathly Den",
    "The Cursed Colony",
    "The Desolate Outskirts",
    "The Poisoned Plains",
    "The Rusty Ruins",
    "The Toxic Tundra",
    "Wasteland Watchtower",
    "Ashen Fields",
    "Bloodspike Bastion",
    "Cursed Oasis",
    "Goliath's Graveyard",
    "Junktown",
    "Mournful Marshes",
    "Outcast Outpost",
    "Ransacker's Roost",
    "The Charred Plains",
    "The Mutant's Den",
  ],
  city: [
    "Hell's Haven",
    "Deadend City",
    "Pomaria",
    "Courage Fall",
    "Remembrance",
    "Men's Rest",
    "Omnadon",
    "Firebend",
    "Ashtown",
    "Malicity",
    "Asturica",
    "Everwinter",
    "Cavity",
    "Eternity",
    "Demonia",
    "Hazardine",
    "Murkville",
    "Tempadon",
    "Forsaken Falls",
    "Terminus",
    "Charington",
    "Tribulation",
    "Ashbourne",
    "Marsonia",
    "Anvilorum",
    "Marduk's Grief",
    "Malaise",
    "Sabbat's Rapture",
    "Ceaseton",
    "Mina",
    "Promise",
    "Mictlan",
    "Desolation City",
    "Asphodel",
    "Malady",
    "Carnage",
    "Guilder's Crossing",
    "Undercity Heights",
    "Ashenport",
    "Chimera City",
    "Spirehaven",
    "Sinister Springs",
    "The Demolition District",
    "The Gauntlet",
    "The Shadowed Shores",
    "The Underhive Hub",
    "Dead Man's Gulch",
    "Doom's Crossing",
    "Ferron's Forge",
    "Hive's End",
    "Scrapheap City",
    "Sumpside Junction",
    "Techno-Temple",
    "The Cratered Coast",
  ],
  hive: [
    "Gormenar Secondus",
    "Remembrance Secondus",
    "Sabbatar",
    "Nefaria",
    "Nemesis",
    "Surmise",
    "Absadon Hive",
    "Mordum Secondus",
    "Arkheradon Tertius",
    "Arkhadon Secondus",
    "Youdu Tertius",
    "Nexium Secondus",
    "Sycorus",
    "Rapture",
    "Inferni",
    "Tempestora",
    "Belum Hive",
    "Necropolis",
  ],
};

const sampleTradeRouteNames = [
  "Arterial Way",
  "Spire-shadow Road",
  "Ash Straight",
  "Helmawr's Path",
  "Dune Run",
  "Scrap Way",
  "Tithe Procession",
  "Ore Road",
  "Oilslick Run",
  "The Glitterstream",
  "Nautican Drive",
  "Corpsestarch Road",
  "Mercer's Path",
  "Promethean Freeway",
  "The Slave Drive",
  "Ferros Path",
  "Bullet Road",
  "Hyrdo-Line",
  "Gelt Byway",
  "Stimms Circuit",
];

const sampleRoadNames = {
  nearWastes: [
    "Clearway",
    "Fiveways",
    "Iron Ridge",
    "Spire's shadow",
    "Low Road",
    "Edge Road",
    "Krok Pass",
    "Bullet Gate",
    "Cog Way",
    "High Road",
    "Ore Pass",
    "Blade's Cut",
    "Beast Run",
    "Ore Road",
    "Ten Mile Straight",
    "Aquila Way",
    "Freepass",
    "Express Way",
    "Tech Thoroughfare",
    "Spire Street",
    "Enslaver's Expressway",
    "Guilder's Gateway",
    "Catacomb Crescent",
    "Undercity Undertaking",
    "Wraith's Way",
    "Shadow's Speedway",
  ],
  deepWastes: [
    "Storm Road",
    "The Winds",
    "Grave Run",
    "The Flats",
    "Deeps",
    "Near Reaches",
    "Far Reaches",
    "The Baked Expanse",
    "The Wyrd",
    "Outcast's Passage",
    "Freedom Passage",
    "Critter Caves",
    "Scrap Pass",
    "Promethean Fields",
    "Hairpin Climb",
    "Rock Falls",
    "Derrik Forest",
    "Fools Pass",
    "Deadman Ally",
    "Ash Flat Straight",
    " Rusty Roadway",
    "Ashen Avenue",
    "Corpse Corridor",
    "Chimera Circuit",
    "Sinister Speedway",
    "Gangster's Galore",
    "Deathdrive",
    "Merc's Mile",
    "Skid's Street",
    "Wasteland Wanderer's Way",
  ],
  wildWastes: [
    "The Twin's Pass",
    "Cracked Canyon",
    "Night Passage",
    "Raiders Refuge",
    "Shimmer Falls",
    "Gark's End",
    "Helot's Path",
    "No Hope",
    "Slick Flats",
    "Shifting Sands",
    "Route 666",
    "13 Bends",
    "Purgatory Pass",
    "Outlaw's Outlook",
    "Cursed Causeway",
    "Ashen Alleyway",
    "Necrotic Niche",
    "Sumpside Scenic Route",
    "Toxic Thruway",
    "Rogue's Road",
    "Rustbucket Road",
  ],
};

export { sampleLocationNames, sampleRoadNames, sampleTradeRouteNames };
