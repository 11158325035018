import {
  calculateAngle,
  calculateDistance,
  random,
  selectRandom,
  selectUniqueRandom,
} from "./helpers";
import { sampleRoadNames } from "./sampleNames";

const LOCATION_PADDING = 20;
const ROADS_PER_PLAYER = 4;

// returns an array of roads
const generateRoads = (locations, numberOfPlayers, players) => {
  const connections = buildConnections(locations);
  var roads = splitConnectionsIntoRoads(connections, numberOfPlayers);

  locations.forEach((location) => {
    location.connections = connections
      .filter((c) => c.startLocation === location || c.endLocation === location)
      .map((c) => ({
        destination:
          c.startLocation === location ? c.endLocation : c.startLocation,
        roads: c.roads.length,
      }))
      .reduce((a, v) => ({ ...a, [v.destination.id.toString()]: v.roads }), {});
  });

  players
    .map((p) => p.id)
    .concat(players.map((p) => p.id))
    .forEach((playerId) => {
      const road = selectRandom(
        roads.filter((r) => r.controllingPlayer === null)
      );
      road.controllingPlayer = playerId;
    });

  return roads.sort((a, b) => a.name.localeCompare(b.name));
};

const buildConnections = (locations) => {
  const connections = [];

  locations.forEach((startLocation) => {
    locations
      .filter((endLocation) => endLocation !== startLocation)
      .map((endLocation) => ({
        endLocation: endLocation,
        distance: calculateDistance(startLocation.point, endLocation.point),
      }))
      .sort((a, b) => (a.distance <= b.distance ? -1 : 1))
      .slice(0, random(2, 3))
      .forEach(({ endLocation, distance }) => {
        if (isDuplicateConnection(connections, startLocation, endLocation))
          return;

        const angle = calculateAngle(startLocation.point, endLocation.point);
        const startPoint = {
          x: startLocation.point.x + LOCATION_PADDING * Math.cos(angle),
          y: startLocation.point.y + LOCATION_PADDING * Math.sin(angle),
        };
        const endPoint = {
          x: endLocation.point.x - LOCATION_PADDING * Math.cos(angle),
          y: endLocation.point.y - LOCATION_PADDING * Math.sin(angle),
        };
        connections.push({
          startLocation,
          endLocation,
          startPoint,
          endPoint,
          distance,
          roads: [],
        });
      });
  });

  return connections;
};

const splitConnectionsIntoRoads = (connections, numberOfPlayers) => {
  distributeRoadsOverConnections(connections, numberOfPlayers);

  connections.forEach((connection) => {
    var startPoint = connection.startPoint;
    connection.roads.forEach((road, index) => {
      const endPoint = {
        x:
          connection.startPoint.x +
          ((connection.endPoint.x - connection.startPoint.x) /
            connection.roads.length) *
            (index + 1),
        y:
          connection.startPoint.y +
          ((connection.endPoint.y - connection.startPoint.y) /
            connection.roads.length) *
            (index + 1),
      };
      road.startPoint = startPoint;
      road.endPoint = endPoint;
      road.startLocation = index === 0 ? connection.startLocation : null;
      road.endLocation =
        index === connection.roads.length - 1 ? connection.endLocation : null;
      road.region = assignRegion(
        index,
        connection.roads.length,
        connection.startLocation,
        connection.endLocation
      );
      road.controllingPlayer = null;
      startPoint = endPoint;
    });
  });

  connections.forEach((c) => {
    c.startLocation.roadConnections.push({
      otherLocationId: c.endLocation.id,
      roadIds: c.roads.map((r) => r.id),
    });
    c.endLocation.roadConnections.push({
      otherLocationId: c.startLocation.id,
      roadIds: c.roads.map((r) => r.id),
    });
  });

  var roads = connections.flatMap((c) => c.roads);
  generateRoadNames(roads);
  assignIncome(roads);

  return roads;
};

const distributeRoadsOverConnections = (connections, numberOfPlayers) => {
  Array.from(
    { length: numberOfPlayers * ROADS_PER_PLAYER },
    (_, index) => index + 1
  )
    .map((i) => ({ id: i }))
    .forEach((road) => {
      var connection;
      const unlinkedConnections = connections.filter(
        (c) => c.roads.length === 0
      );
      if (unlinkedConnections.length > 0) {
        connection = unlinkedConnections[0];
      } else {
        connection = selectRandom(connections);
      }

      connection.roads.push(road);
    });
};

const generateRoadNames = (roads) => {
  roads.forEach((road) => {
    var name;
    switch (road.region) {
      case "Near Wastes":
      default:
        name = selectUniqueRandom(
          sampleRoadNames.nearWastes,
          roads.map((r) => r.name)
        );
        break;

      case "Deep Wastes":
        name = selectUniqueRandom(
          sampleRoadNames.deepWastes,
          roads.map((r) => r.name)
        );
        break;

      case "Wild Wastes":
        name = selectUniqueRandom(
          sampleRoadNames.wildWastes,
          roads.map((r) => r.name)
        );
        break;
    }

    road.name = name;
  });
};

const assignRegion = (index, length, startLocation, endLocation) => {
  var region = "Wild Wastes";
  if (
    (index === 0 && startLocation.type !== "town") ||
    (index === length - 1 && endLocation.type !== "town")
  ) {
    region = "Near Wastes";
  } else if (
    (index === 0 && startLocation.type === "town") ||
    (index === length - 1 && endLocation.type === "town") ||
    (index === 1 && startLocation.type !== "town") ||
    (index + 1 === length - 1 && endLocation.type !== "town")
  ) {
    region = "Deep Wastes";
  }
  return region;
};

const assignIncome = (roads) => {
  const lowIncome = Math.floor(roads.length / 3);
  const highIncome = Math.floor(roads.length / 3);

  roads
    .sort(() => (Math.random() > 0.5 ? 1 : -1))
    .forEach((road, index) => {
      if (index < lowIncome) road.income = "D6 credits";
      else if (index < lowIncome + highIncome) road.income = "3D6 credits";
      else road.income = "2D6 credits";
    });
};

// returns true if the connection form by the startLocation and endLocation
// is a duplicate (in either direction) of any existing connection in connections
const isDuplicateConnection = (connections, startLocation, endLocation) => {
  return connections.some(
    (c) =>
      (c.startLocation === startLocation && c.endLocation === endLocation) ||
      (c.startLocation === endLocation && c.endLocation === startLocation)
  );
};

export { generateRoads };
